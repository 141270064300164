import React, { useState, useContext } from 'react';
import ProspectContext from '../components/ProspectContext.js'

const TCPA = () => {
    const { tcpa, updateTCPA } = useContext(ProspectContext)
    const { prospect, updateProspect } = useContext(ProspectContext)
    //const [tcpa, setTcpa] = useState(1)
    const [checked, setChecked] = useState(true)

    const handleChange = () => {
        setChecked(!checked)
        if (checked === true) {
            updateTCPA(1)
        }
        else if (checked === false) {
            updateTCPA('null')
        }
    }
    return (
        <div id="privacy_disclaimer">
            <div className="disclaimer-text">
                {/* <p className="line-clamp"> */}<p><input type="checkbox" name="privacy_disclosure" required onChange={handleChange} ></input>
                    By clicking "Start Quote", you authorize Insurance321.com to provide 
                    your information to an independent 
                    agent or an insurance partner to call and/or text message the phone number you have provided to us, which may include 
                    your wireless number, and to leave you a prerecorded message, email message or SMS messages using automated technology 
                    about the quote and rates. You are not required to provide this consent to purchase insurance from an independent agent 
                    or insurance partner. <a href="/privacy-policy/"><span style={{textDecoration:'underline', fontFamily:'Helvetica Bold'}}>View Privacy Policy</span></a>
                </p>
            </div>
        </div>
      );
}
 
export default TCPA;