import React, { useContext, useEffect } from 'react';
import CurrentContext from '../../components/CurrentContext.js'

const MultiStepNav = (props) => {
    const {current, setCurrent} = useContext(CurrentContext)
    const nextStep = () => {       
        setCurrent(props.nextStep)
    }
    const prevStep = () => {
        setCurrent(props.prevStep)
    }
    
    return (
        <div id="multistep_nav">
            <button onClick={prevStep} className="nav-button" id="prev_button">Back</button>
            <button onClick={nextStep} className="nav-button" id="skip_button">Skip</button>
        </div>
     );
}
 
export default MultiStepNav;